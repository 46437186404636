html, body, #root{
  height: 100%;
}

div {
  display: flex; 
  flex-direction: column;
  flex: 1;
}

.App {
    background-image: url("./assets/images/Background light.png");
    background-size: cover;
    height: 100%;
}

.App .logo{
  width: 50%;
  margin-bottom: -2em;
}

.logo_container{
  align-items: center;
  justify-content: center;
}

.subtitle {
  font-weight: bold; 
  font-size: 3em; 
  margin-top: 0;
  opacity: 0.5;
}

a {
  font-weight: bold;
  color: #45311e;
}
